import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsPhone, BsEnvelope, BsMap } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Joy прокат автомобілів
			</title>
			<meta name={"description"} content={"Їдьте з комфортом, досліджуйте з упевненістю - Joy, ваш дружній партнер у подорожі"} />
			<meta property={"og:title"} content={"Joy прокат автомобілів"} />
			<meta property={"og:description"} content={"Їдьте з комфортом, досліджуйте з упевненістю - Joy, ваш дружній партнер у подорожі"} />
			<meta property={"og:image"} content={"https://brewajoy.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://brewajoy.com/img/46236236.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brewajoy.com/img/46236236.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brewajoy.com/img/46236236.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brewajoy.com/img/46236236.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Contacts-5" background="linear-gradient(0deg,rgba(255, 255, 255, 0.5) 0%,rgba(255, 255, 255, 0.5) 100%),rgba(0, 0, 0, 0) url(https://brewajoy.com/img/7.jpg) 0% 0% /cover repeat scroll padding-box">
			<Override slot="SectionContent" align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="--headline1"
				color="--primary"
				text-align="center"
				padding="0px 0 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
			>
				Контакти
			</Text>
			<Text
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				margin="16px 0 50px 0px"
				color="#422612"
				width="700px"
				md-width="auto"
			>
				Незалежно від того, чи маєте ви запитання, відгук чи просто хочете зв’язатися, ми тут, щоб вислухати. Зв'язатися з нами можна за:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 50px 0px"
				lg-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="24px"
					background="rgba(255, 255, 255, 0.6)"
				>
					<Icon
						category="bs"
						icon={BsPhone}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Телефоном
					</Text>
					<Link
						href="tel:+38(097)453-43-34"
						color="#47474d"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 15px 0px"
					>
						+38(097)453-43-34
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="24px"
					background="rgba(255, 255, 255, 0.6)"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Електронною поштою
					</Text>
					<Link
						href="mailto:manager@brewajoy.com"
						color="#47474d"
						text-decoration-line="initial"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 15px 0px"
						text-align="center"
					>
						manager@brewajoy.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="36px 20px 20px 20px"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					border-radius="24px"
					background="rgba(255, 255, 255, 0.6)"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="36px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 24px/1.2 --fontFamily-sansHelvetica"
						text-align="center"
						color="--primary"
						md-margin="0px 0px 20px 0px"
					>
						Завітати
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						color="#47474d"
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						вул. Городище 8, м. Трускавець, Львівська обл., 82200
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});